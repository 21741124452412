<!-- eslint-disable vue/no-mutating-props -->
<template>
  <Form
    :validation-schema="schema"
    @submit="Submit"
    ref="addReviewModalFormRef"
    v-slot="{ errors }"
  >
    <div
      class="modal fade"
      id="addReviewModal"
      tabindex="-1"
      aria-labelledby="addReviewModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="heading-06 text-neutral-07 text-center mb-32px">Scrie o recenzie</div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="name" class="text-neutral-07 text-button-02 mb-2">Nume complet:</label>
              <input
                disabled
                name="name"
                v-model="user.UserName"
                type="text"
                class="form-control"
                id="name"
                placeholder="Nume complet"
              />
            </div>
            <div class="mb-3" :class="{ 'invalid-input': errors.comment }">
              <label for="comment" class="text-neutral-07 text-button-02 mb-2">Mesaj:</label>
              <Field
                as="textarea"
                v-model="review.Comment"
                name="comment"
                class="form-control"
                id="comment"
                placeholder="Mesaj"
              ></Field>
              <ErrorMessage name="comment" class="error-message" />
            </div>
            <div class="mb-3" :class="{ 'invalid-input': errors.order_id }">
              <label for="order_id" class="text-neutral-07 text-button-02 mb-2">ID comandă</label>
              <Field
                class="form-control"
                id="order_id"
                name="order_id"
                placeholder="Numărul comenzii"
                type="number"
                v-model="review.OrderId"
              />
              <ErrorMessage name="order_id" class="error-message" />
            </div>
            <div class="mb-3">
              <label class="text-neutral-07 text-button-02 mb-2">Rating</label>
              <div class="row">
                <div
                  class="col-auto"
                  v-for="(rat, index) in 5"
                  :key="index"
                  :class="{ 'pe-2px': index < 5 }"
                >
                  <div v-if="index + 1 <= review.Rating" class="pointer">
                    <img src="../../../assets/images/icons/star-filled-20x20.svg" @click="review.Rating = index + 1"/>
                  </div>
                  <div v-else class="pointer">
                    <img src="../../../assets/images/icons/star-empty-20x20.svg" @click="review.Rating = index + 1"/>
                  </div>
                </div>
                <Field v-model="review.Rating" name="rating" class="d-none"></Field>
                <ErrorMessage name="rating" class="error-message" />
              </div>
            </div>
            <hr />
            <button class="button btn-medium-wider btn-primary-solid d-block w-100 mt-3">
              Adaugă recenzie
            </button>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddReview',
  props: {
    user: Object,
    productId: Number,
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      review: {
        Rating: 0,
      },
    };
  },
  methods: {
    Submit() {
      this.$emit('add', this.review);
    },
    ClearModal() {
      this.$refs.addReviewModalFormRef.resetForm();
    },
  },
  computed: {
    GetOrderExistence() {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      return this.$axios
        .get(`/Order/orderExistence/${this.review.OrderId}/${this.productId}`)
        .then(() => true)
        .catch(() => false);
    },
    schema() {
      return yup.object({
        // name: yup.string().required(),
        rating: yup.number().min(1).max(5).required('Minim o stea este obligatorie.'),
        comment: yup.string().notRequired().max(500, 'Review-ul nu poate fi mai lung de 500 de caractere'),
        order_id: yup
          .number()
          .notRequired()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .test(
            'order_exist',
            'Aceasta comanda nu exista sau nu contine acest produs',
            async (orderId) => {
              if (!orderId) {
                return true;
              }
              const orderExists = await this.GetOrderExistence;
              return orderExists;
            },
          ),
      });
    },
  },
};
</script>

<style scoped>
hr {
  border-color: #d9d9d9 !important;
}
.pe-2px {
  padding-right: 2px !important;
}
</style>
