<!-- eslint-disable comma-dangle -->
<template>
  <div class="container mt-5 pb-5 mb-5">
    <!--breadcrumbs-->
    <div class="row align-items-center jutify-content-start mt-4 paragraph-03 text-neutral-05">
      <div class="col-auto pe-2">
        <router-link :to="{ name: 'HomePage' }" class="text-neutral-05"> Acasă </router-link>
      </div>
      <div class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg" />
      </div>
      <div class="col-auto pe-2">
        <router-link :to="{ name: 'Products' }" class="text-neutral-05"> Produse </router-link>
      </div>
      <div class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg" />
      </div>
      <div v-if="product.CategoryName" class="col-auto pe-2">
        <router-link
          :to="{
            name: 'Products',
            params: {
              categoryName: $utils.StringToSlug(product.CategoryName),
              categoryId: product.CategoryId,
            },
          }"
          class="text-neutral-05"
        >
          {{ product.CategoryName }}
        </router-link>
      </div>
      <div v-if="product.CategoryName" class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg" />
      </div>
      <div
        v-if="product.Title"
        class="text-button-02 col-auto text-secondary-05"
        :title="product.Title"
      >
        {{ this.$utils.Ellipsify(product.Title, 50) }}
      </div>
    </div>
    <!--gallery and config-->
    <div class="row mt-4 pt-3 m-0">
      <div class="col-xl-6 col-md-5">
        <div class="d-md-none mb-5">
          <div class="row align-items-center">
            <div class="col">
              <div class="heading-05 text-neutral-07">{{ product.Title }}</div>
              <div class="row align-items-center me-0 ms-0 mt-1">
                <RatingComponent :stars="product.NumbersOfStars" />
                <div
                  class="col-2 text-button-03 text-neutral-07 fw-bold pe-0"
                  style="padding-left: 2px"
                >
                  ({{ product.NumberOfReviews }})
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="favorite-circle">
                <div v-if="product.IsFavorit">
                  <img
                    src="../../assets/images/icons/heart-filled.svg"
                    @click="ChangeFavoriteStatus(product)"
                    alt="..."
                  />
                </div>
                <div v-else>
                  <img
                    src="../../assets/images/icons/heart-empty.svg"
                    @click="ChangeFavoriteStatus(product)"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="paragraph-02 text-neutral-05 mt-3">
            {{ product.ShortDescription }}
          </div>
        </div>

        <div class="position-relative set-overflow">
          <img
            class="gallery-thumbnail"
            :src="thumbnailImage"
            alt="..."
            @error="$utils.ShowDefaultImage"
          />
          <div v-if="!thumbnailImage" class="no-images-text">Nu au fost adăugate imagini</div>
          <!-- <div class="money-saved-badge-position" v-if="product.PriceDetails.MoneySaved > 0">
            <div class="money-saved-badge">
              <span v-if="selectedConfigurationValue" class="heading-06 text-white fw-medium">
                -{{ (product.PriceDetails.MoneySaved * selectedConfigurationValue).toFixed(2) }} lei
              </span>
              <span v-else class="heading-06 text-white fw-medium">
                -{{ product.PriceDetails.MoneySaved}} lei
              </span>
            </div>
          </div> -->
        </div>
        <div v-if="gallery.length > 3">
          <carousel
            :wrap-around="true"
            :items-to-show="galleryItems"
            class="mt-2 pt-1 gallery-carousel"
            ref="galleryCarouselRef"
          >
            <slide v-for="(image, index) in gallery" :key="index">
              <img
                class="gallery-images"
                :src="image"
                :class="{ active: thumbnailImage === image }"
                alt="..."
                @click="SelectImage(image, index, null)"
                @error="$utils.ShowDefaultImage"
              />
            </slide>
            <template #addons>
              <navigation>
                <template #prev>
                  <img
                    src="../../assets/images/icons/carousel-arrow-left-20x20.svg"
                    alt="..."
                    @click="SelectImage('', -1)"
                    width="20"
                    height="20"
                  />
                </template>
                <template #next>
                  <img
                    src="../../assets/images/icons/carousel-arrow-right-20x20.svg"
                    alt="..."
                    @click="SelectImage('', 1)"
                    width="20"
                    height="20"
                  />
                </template>
              </navigation>
              <pagination />
            </template>
          </carousel>
        </div>
        <div
          class="row mt-3 pt-1"
          v-if="gallery.length > 1 && gallery.length < 4 && parseInt(windowWidth) > 992"
        >
          <div class="col-auto" v-for="(image, index) in gallery" :key="index">
            <img
              class="gallery-images"
              :src="image"
              :class="{ active: thumbnailImage === image }"
              alt="..."
              @click="SelectImage(image, index, null)"
              @error="$utils.ShowDefaultImage"
            />
          </div>
        </div>
        <div v-if="gallery.length > 1 && gallery.length < 4 && parseInt(windowWidth) <= 992">
          <carousel
            :wrap-around="true"
            :items-to-show="galleryItems"
            class="mt-2 pt-1 gallery-carousel"
            ref="galleryCarouselRef"
          >
            <slide v-for="(image, index) in gallery" :key="index">
              <img
                class="gallery-images"
                :src="image"
                :class="{ active: thumbnailImage === image }"
                alt="..."
                @click="SelectImage(image, index, null)"
              />
            </slide>
            <template #addons>
              <navigation>
                <template #prev>
                  <img
                    src="../../assets/images/icons/carousel-arrow-left-20x20.svg"
                    alt="..."
                    @click="SelectImage('', -1)"
                    width="20"
                    height="20"
                  />
                </template>
                <template #next>
                  <img
                    src="../../assets/images/icons/carousel-arrow-right-20x20.svg"
                    alt="..."
                    @click="SelectImage('', 1)"
                    width="20"
                    height="20"
                  />
                </template>
              </navigation>
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
      <div class="col-xxl-1 col-md-auto d-none d-md-block"></div>
      <div class="col">
        <div>
          <div class="d-none d-md-block">
            <div class="row align-items-center">
              <div class="col">
                <div class="heading-05 text-neutral-07">
                  {{ product.Title }}
                </div>
                <div class="row align-items-center me-0 ms-0 mt-1">
                  <RatingComponent :stars="product.NumbersOfStars" />
                  <div
                    class="col-2 text-button-03 text-neutral-07 fw-bold pe-0"
                    style="padding-left: 2px"
                  >
                    ({{ product.NumberOfReviews }})
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="favorite-circle">
                  <div v-if="product.IsFavorit">
                    <img
                      src="../../assets/images/icons/heart-filled.svg"
                      @click="ChangeFavoriteStatus(product)"
                      alt="..."
                    />
                  </div>
                  <div v-else>
                    <img
                      src="../../assets/images/icons/heart-empty.svg"
                      @click="ChangeFavoriteStatus(product)"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="paragraph-02 text-neutral-05 mt-3">
              {{ product.ShortDescription }}
            </div>
            <div
              class="not-available-product fw-semibold text-center mt-4 ps-3 pe-3"
              v-if="product.OutOfStock == true || product.IsNotAvailable == true"
            >
              <div v-if="product.OutOfStock">Indisponibil</div>
              <div v-else>Produsul nu este valabil</div>
            </div>
          </div>
          <!--configs-->
          <Form
            ref="configFormRef"
            :validation-schema="Schema"
            v-slot="{ errors }"
            v-if="product.OutOfStock == false && product.IsNotAvailable == false"
          >
            <div
              class="config-box"
              style="margin-top: 51px"
              v-if="config && config.Items && config.Items.length > 0"
            >
              <div class="description">{{ config.Category }}</div>
              <FieldArray name="configurations">
                <div class="row">
                  <div
                    v-for="(configuration, index) in config.Items"
                    :key="index"
                    class="col-6 col-sm-4 mb-4 mb-sm-0"
                    :class="{ 'mb-sm-4': index < 3 && config.Items.length > 3 }"
                  >
                    <div
                      class="d-inline-flex align-items-center"
                      @change="GetSubconfigurations(configuration, 0)"
                    >
                      <Field
                        v-slot="{ field }"
                        :name="`configurations[${index}].isSelected`"
                        v-model="configuration.isSelected"
                        :class="{ 'invalid-input': errors[`configurations[${index}].isSelected`] }"
                      >
                        <input
                          v-bind="field"
                          class="form-check-input me-2 mt-0"
                          type="radio"
                          name="config-radio"
                          :id="`config-${configuration.Name}-${configuration.Id}`"
                        />
                        <label
                          class="form-check-label"
                          :class="{ 'fw-semibold': configuration.isSelected }"
                          :for="`config-${configuration.Name}-${configuration.Id}`"
                        >
                          {{ configuration.Name }}
                          <div
                            class="configuration-price"
                            :class="{ 'configuration-color ': configuration.isSelected }"
                            v-if="configuration.Value"
                          >
                            {{ $utils.DisplayProductPrice(configuration.Value) }}
                            lei
                          </div>
                          <div
                            class="configuration-price"
                            :class="{ 'configuration-color ': configuration.isSelected }"
                            v-else
                          >
                            0 lei
                          </div>
                        </label>
                      </Field>
                    </div>
                  </div>
                </div>
              </FieldArray>
            </div>
            <ErrorMessage name="configurations" class="error-message" />
          </Form>
          <!--subconfigs-->
          <Form ref="subconfigFormRef" :validation-schema="recursiveSchema" v-slot="{ errors }">
            <FieldArray name="config">
              <div
                v-for="(subconfiguration, index) in subconfigurations"
                :key="index"
                style="margin-top: 51px"
              >
                <div class="config-box">
                  <div class="description">{{ subconfiguration.Description }}</div>
                  <FieldArray :name="`config[${index}].specifications`">
                    <div class="row">
                      <div
                        v-for="(config, indexSubConfig) in subconfiguration.Configurations"
                        :key="indexSubConfig"
                        class="col-6 col-sm-4 mb-4 mb-sm-0"
                        :class="{
                          'mb-sm-4': index < 3 && subconfiguration.Configurations.length > 3,
                        }"
                      >
                        <div class="d-inline-flex align-items-center">
                          <Field
                            @change="GetSubconfigurations(config, index + 1)"
                            v-slot="{ field }"
                            :name="`config[${index}].specifications[${indexSubConfig}].isSelected`"
                            v-model="config.isSelected"
                            :class="{
                              'invalid-input':
                                errors[
                                  `config[${index}].specifications[${indexSubConfig}].isSelected`
                                ],
                            }"
                          >
                            <input
                              v-bind="field"
                              class="form-check-input me-2 mt-0"
                              type="radio"
                              :name="`config[${index}]`"
                              :id="`config[${index}][${indexSubConfig}]`"
                            />
                          </Field>
                          <label
                            class="form-check-label"
                            :class="{ 'fw-semibold': config.isSelected }"
                            :for="`config[${index}][${indexSubConfig}]`"
                          >
                            {{ config.Name }}
                            <div
                              class="configuration-price"
                              :class="{ 'configuration-color ': config.isSelected }"
                              v-if="config.Value"
                            >
                              +{{ $utils.DisplayProductPrice(config.Value) }} lei
                            </div>
                            <div
                              class="configuration-price"
                              :class="{ 'configuration-color ': config.isSelected }"
                              v-else
                            >
                              +0 lei
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </FieldArray>
                </div>
                <ErrorMessage :name="`config[${index}].specifications`" class="error-message" />
              </div>
            </FieldArray>
          </Form>
        </div>
        <!--total price & btns-->
        <div
          class="mt-5 d-none d-lg-block"
          v-if="product.OutOfStock == false && product.IsNotAvailable == false"
        >
          <span class="total-price-title">Total</span>

          <div class="row">
            <div class="col-lg order-1 order-lg-0 text-neutral-07 mt-3 mt-lg-0">
              <div class="heading-04">{{ $utils.DisplayProductPrice(price) }} lei</div>
              <div
                v-if="
                  this.product.PriceDetails.OnSalePrice > 0 && product.HasConfigurations === false
                "
              >
                <span class="heading-05 text-neutral-08 me-1 original-price">
                  {{ $utils.DisplayProductPrice(regularPrice) }}
                  <!-- {{ (this.product.PriceDetails.RegularPrice * product.Amount * selectedConfigurationValue).toFixed(2) }} -->
                </span>
                <span class="paragraph-03 text-danger-05">lei</span>
              </div>
            </div>
            <!-- v-if="product.OutOfStock == false && product.IsNotAvailable == false" -->
            <div class="col-auto order-0">
              <div class="add-to-cart-section" style="height: 47.88px !important">
                <div class="row">
                  <div class="col-auto pe-0" style="width: 100px">
                    <div class="input-group right h-100">
                      <input
                        type="number"
                        class="form-control product-amount-input"
                        v-model="product.Amount"
                        @input="ConfigurePrice()"
                      />
                      <span class="input-group-text">
                        <img
                          src="../../assets/images/icons/pink-chevron-up-20x20.svg"
                          @click="ChangeAmount(1)"
                          alt="..."
                        />
                        <img
                          src="../../assets/images/icons/pink-chevron-down-20x20.svg"
                          :class="{ disabled: product.Amount <= 1 }"
                          @click="product.Amount > 1 ? ChangeAmount(-1) : ''"
                          alt="..."
                        />
                      </span>
                    </div>
                  </div>
                  <div class="col ps-3">
                    <button
                      @click="product.Amount > 0 ? AddProductToCart() : ''"
                      type="button"
                      :class="{
                        'disabled-btn btn-disabled-solid button btn-medium text-button-02 w-100 ps-5 pe-5 d-flex align-items-center':
                          product.Amount <= 0,
                        'button btn-medium btn-primary-solid text-button-02 w-100 ps-5 pe-5 d-flex align-items-center':
                          product.Amount > 0,
                      }"
                    >
                      <img src="../../assets/images/icons/cart-bold-filled-20x20.svg" alt="..." />
                      Pune în coș
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-lg-none">
        <div
          v-if="product.OutOfStock == true || product.IsNotAvailable == true"
          class="text-center mt-3"
        >
          <div class="not-available-product fw-semibold text-center ps-3 pe-3">
            <div v-if="product.OutOfStock">Indisponibil</div>
            <div v-else>Produsul nu este valabil</div>
          </div>
        </div>

        <!--total price & btns-->
        <span
          class="total-price-title mt-5 d-none d-sm-inline-block"
          v-if="product.OutOfStock == false && product.IsNotAvailable == false"
          >Total</span
        >

        <div
          class="row mt-5 mt-sm-0"
          v-if="product.OutOfStock == false && product.IsNotAvailable == false"
        >
          <div class="col-sm order-1 order-sm-0 text-neutral-07 mt-3 mt-sm-0">
            <span class="total-price-title d-sm-none">Total</span>
            <div class="heading-04">{{ $utils.DisplayProductPrice(price) }} lei</div>
            <div
              v-if="
                this.product.PriceDetails.OnSalePrice > 0 && product.HasConfigurations === false
              "
            >
              <span class="heading-05 text-neutral-08 me-1 original-price">
                {{ $utils.DisplayProductPrice(regularPrice) }}
              </span>
              <span class="paragraph-03 text-danger-05">lei</span>
            </div>
          </div>
          <div
            class="col col-sm-auto order-0"
            v-if="product.OutOfStock == false && product.IsNotAvailable == false"
          >
            <div class="add-to-cart-section" style="height: 47.88px !important">
              <div class="row align-items-center justify-content-between justify-content-sm-start">
                <div class="col-auto pe-0 d-none d-md-block" style="width: 100px">
                  <div class="input-group right h-100">
                    <input type="number" class="form-control" v-model="product.Amount" />
                    <span class="input-group-text">
                      <img
                        src="../../assets/images/icons/pink-chevron-up-20x20.svg"
                        @click="ChangeAmount(1)"
                        alt="..."
                      />
                      <img
                        src="../../assets/images/icons/pink-chevron-down-20x20.svg"
                        :class="{ disabled: product.Amount <= 1 }"
                        @click="product.Amount > 1 ? ChangeAmount(-1) : ''"
                        alt="..."
                      />
                    </span>
                  </div>
                </div>
                <div class="col-auto d-md-none">
                  <div class="row amount-buttons align-items-center justify-content-between">
                    <div class="col-auto">
                      <button
                        type="button"
                        class="button"
                        :disabled="product.Amount == 1"
                        @click="product.Amount > 1 ? ChangeAmount(-1) : ''"
                      >
                        <img src="../../assets/images/icons/minus-gray-16x16.svg" alt="..." />
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="heading-06 text-neutral-05">
                        {{ product.Amount }}
                      </div>
                    </div>
                    <div class="col-auto">
                      <button type="button" class="button" @click="ChangeAmount(1)">
                        <img src="../../assets/images/icons/plus-gray-16x16.svg" alt="..." />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-auto ps-3">
                  <button
                    @click="product.Amount > 0 ? AddProductToCart() : ''"
                    type="button"
                    :class="{ 'disabled-solid': product.Amount <= 0 }"
                    class="button btn-medium btn-primary-solid text-button-02 d-flex align-items-center"
                  >
                    <img src="../../assets/images/icons/cart-bold-filled-20x20.svg" alt="..." />
                    <span>Pune în coș</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--description and reviews-->
    <div class="section">
      <div class="content">
        <div class="heading-07 text-neutral-08 mb-3" style="margin-top: 40px">Descriere</div>
        <div v-if="product.Description">
          <div v-html="product.Description"></div>
        </div>
        <div class="section" style="margin-top: 40px">
          <div
            style="margin-top: 40px"
            class="row align-items-center justify-content-center justify-content-sm-start mb-4"
          >
            <div class="col-12 text-center text-sm-start col-sm mb-3 mb-sm-0">
              <div>
                <span class="heading-07 text-neutral-08 fw-semibold mb-3">Recenzii</span>
                <span class="reviews-number"> ({{ reviewsPagination.TotalItemCount }}) </span>
              </div>
              <div
                class="row align-items-center mt-2 justify-content-center justify-content-sm-start"
                v-if="product.AverageRating && product.NumbersOfStars"
              >
                <div class="col-auto">
                  <div class="heading-03 text-neutral-07">{{ product.AverageRating || 0 }}</div>
                </div>
                <RatingComponent :stars="product.NumbersOfStars" />
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="button btn-medium-wider btn-primary-solid text-button-02"
                @click="currentUser ? GetUserDetails() : logIn()"
              >
                Adaugă o recenzie
              </button>
            </div>
          </div>
          <div class="mb-2 card review" v-for="(review, index) in reviews" :key="index">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="text-button-01 text-neutral-08 mb-1">{{ review.UserName }}</div>
                  <div class="date mb-2 pb-1">{{ review.Date }}</div>
                  <div class="text-neutral-05 paragraph-03 d-none d-md-block">
                    {{ review.Comment }}
                  </div>
                </div>
                <div class="col-auto">
                  <div class="row">
                    <RatingComponent :stars="review.Rating" />
                    <div class="col-auto text-button-01 text-neutral-07 ps-1">
                      {{ review.Rating }}.0
                    </div>
                  </div>
                  <div
                    v-if="
                      review.IsUserReview &&
                      product.OutOfStock == false &&
                      product.IsNotAvailable == false
                    "
                    class="row align-items-center"
                  >
                    <div class="col review-aproved" v-if="review.IsAproved">Aprobat</div>
                    <div class="col review-pending" v-else>În așteptare</div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="button table-btn btn-small"
                        @click="GetReview(review.Id)"
                        v-if="
                          review.IsUserReview &&
                          product.OutOfStock == false &&
                          product.IsNotAvailable == false
                        "
                      >
                        <img src="../../assets/images/icons/table-edit-20x20.svg" alt="..." />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-neutral-05 paragraph-03 d-md-none">
                {{ review.Comment }}
              </div>
            </div>
          </div>
          <div class="text-center mt-5" v-if="reviews.length == 0">
            <h1 class="heading-08 text-neutral-05">Adaugă prima recenzie</h1>
            <img
              src="../../assets/images/add-first-review.webp"
              class="add-first-review-img"
              alt="..."
            />
          </div>
          <div
            class="text-center"
            v-if="reviewsPagination.TotalPageCount > reviewsFilter.PageNumber"
          >
            <button
              class="button btn-medium-wider btn-primary-outlined fw-semibold mt-3"
              @click="ShowMoreReviews"
            >
              Arată mai multe
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="share-article-section">
      <div class="title">Distribuie produsul:</div>
      <div class="row mt-2">
        <div class="col-auto">
          <button
            type="button"
            @click="ShareOnPlatform('facebookShare')"
            title="Distribuie pe Facebook"
          >
            <img :src="DisplaySocialMediaIcon('facebook')" />
          </button>
        </div>
        <div class="col-auto d-sm-none">
          <a
            :href="`whatsapp://send?text=${currentURL}`"
            data-action="share/whatsapp/share"
            title="Distribuie pe WhatsApp"
            rel="noopener noreferrer"
          >
            <img :src="DisplaySocialMediaIcon('whatsapp')" />
          </a>
          <button type="button"></button>
        </div>
        <div class="col-auto">
          <a
            :href="`mailto:?subject=Delicitate - Vezi acest produs!&amp;body=${currentURL}`"
            title="Trimite un e-mail cu acest produs"
            rel="noopener noreferrer"
          >
            <img :src="DisplaySocialMediaIcon('email')" />
          </a>
        </div>
      </div>
    </div>

    <!--recommended products-->
    <div
      class="heading heading-05 text-center text-secondary-03"
      style="margin-top: 80px"
      v-if="suggestedProducts && suggestedProducts.length > 0"
    >
      Produse sugerate
    </div>
    <div class="section mt-2" v-if="suggestedProducts && suggestedProducts.length > 0">
      <div class="row mt-4 pt-3 ms-0 me-0">
        <div
          class="col-6 col-lg-4 col-xl-3 mb-3 pb-1"
          v-for="(product, index) in suggestedProducts"
          :key="index"
          @mouseover="product.ShowCartButtons = true"
          @mouseleave="product.ShowCartButtons = false"
        >
          <DisplayProduct :product="product" @change="ChangeFavoriteStatus" />
          <add-to-cart-component :product="product" />
        </div>
      </div>
    </div>
  </div>
  <AddReview ref="addReviewModalRef" :user="user" :productId="product.Id" @add="CreateReview" />
  <EditReview :review="review" :productId="product.Id" @edit="UpdateReview" />
  <template v-if="showSuccessPopup && popUpComponent">
    <component :is="popUpComponent" @close-pop-up="CloseSuccessPopUp()" />
  </template>
</template>

<script>
/* eslint-disable */
import RatingComponent from '../../components/General/RatingComponent.vue';
import DisplayProduct from '../../components/Product/DisplayProductComponent.vue';
import AddReview from '../../components/Product/Modals/AddReviewModalComponent.vue';
import EditReview from '../../components/Product/Modals/EditReviewModalComponent.vue';

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import AddToCartComponent from '../../components/General/AddToCartComponent.vue';

import * as yup from '../../../node_modules/yup';
import { Form, Field, ErrorMessage, FieldArray } from '../../../node_modules/vee-validate';
import NotFoundComponent from '../../components/General/NotFoundComponent.vue';

import { markRaw } from 'vue';
import ProductAddedToCartPopUpComponent from '../../components/Product/ProductAddedToCartPopUpComponent.vue';

export default {
  name: 'ProductView',
  props: {
    productIdParam: {
      type: String,
      required: false,
    },
    productName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      review: {},
      user: {},
      requestsDone: 0,
      product: {
        PriceDetails: {
          RegularPrice: 0,
          OnSalePrice: 0,
          MoneySaved: 0,
          Amount: 1,
        },
      },
      gallery: [],
      reviews: [],
      config: [],
      configurationCategory: '',
      selectedConfigurations: [],
      subconfigurations: [],
      suggestedProducts: [],
      reviewsFilter: {
        PageSize: 4,
        PageNumber: 1,
        OrderBy: 'createdDate_desc',
      },
      reviewsPagination: {},
      thumbnailImage: {},
      imgNumber: 0,
      price: 0,
      galleryItems: 0,
      orderExist: true,
      currentURL: '',
      selectedConfigurationValue: 1,
      regularPrice: 0,
      windowWidth: 0,
      showSuccessPopup: false,
      popUpComponent: null,
    };
  },
  components: {
    RatingComponent,
    DisplayProduct,
    AddReview,
    EditReview,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    AddToCartComponent,
    Form,
    Field,
    ErrorMessage,
    FieldArray,
    NotFoundComponent,
    ProductAddedToCartPopUpComponent,
  },
  methods: {
    ShareOnPlatform() {
      FB.ui(
        {
          method: 'share',
          href: window.location.href,
        },
        () => {}
      );
    },
    DisplaySocialMediaIcon(img) {
      return require(`@/assets/images/SocialMedia/${img}.svg`);
    },
    logIn(closeModal) {
      if (closeModal) {
        $(`#${closeModal}`).modal('hide');
      }
      $('#logInModal').modal('show');
    },
    ShowThumbnail(imageBase64) {
      if (!imageBase64) {
        return require('@/assets/images/default-thumbnail.svg');
      }
      return imageBase64;
    },
    ChangeAmount(increment) {
      if (this.product.Amount + increment > 0) {
        this.product.Amount += increment;
      }
      this.ConfigurePrice();
    },
    SelectImage(image, index) {
      if (image) {
        //onclick
        this.thumbnailImage = image;
        this.imgNumber = index;
      } else {
        this.imgNumber += index;
        if (this.imgNumber === this.gallery.length) this.imgNumber = 0;
        if (this.imgNumber === -1) this.imgNumber = this.gallery.length - 1;
        this.thumbnailImage = this.gallery[this.imgNumber];
      }
    },
    GetReview(id) {
      this.$store.state.loader = true;
      this.$axios
        .get(`/Review/get/${id}`)
        .then((response) => {
          this.review = response.data;
          $('#editReviewModal').modal('show');
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    UpdateReview(review) {
      this.$store.state.loader = true;
      const editReview = {
        Id: review.Id,
        ProductId: review.ProductId,
        Rating: review.Rating,
        Comment: review.Comment,
        ...(review.OrderId ? { OrderId: review.OrderId } : ''),
      };
      this.$axios
        .put(`/Review/edit/${review.Id}`, editReview)
        .then(() => {
          this.reviewsFilter.PageNumber = 1;
          this.GetProductReviews();
          this.$store.state.loader = false;
          $('#editReviewModal').modal('hide');
          this.$utils.ToastNotify(
            'success',
            'Modificarea recenziei a fost trimisă către aprobare.'
          );
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    CreateReview(review) {
      this.$store.state.loader = true;
      const createReview = {
        ProductId: this.product.Id,
        Rating: review.Rating,
        Comment: review.Comment,
        ...(review.OrderId ? { OrderId: review.OrderId } : ''),
      };
      this.$axios
        .post('/Review/create', createReview)
        .then(() => {
          this.reviewsFilter.PageNumber = 1;
          this.GetProductReviews();
          $('#addReviewModal').modal('hide');
          this.$store.state.loader = false;
          this.$utils.ToastNotify('success', 'Recenzia a fost trimisă către aprobare.');
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    async AddProductToCart() {
      this.CloseSuccessPopUp();
      const configForm = await this.$refs.configFormRef.validate();
      const subconfigFormRef = await this.$refs.subconfigFormRef.validate();
      if (configForm.valid && subconfigFormRef.valid) {
        this.$store.state.loader = true;
        const addProduct = {
          ProductId: this.product.Id,
          ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
          Quantity: this.product.Amount,
          Configurations: this.selectedConfigurations.map((x) => x.Id),
          ...(this.$localStorageMethods.GetCartId()
            ? { CartId: this.$localStorageMethods.GetCartId() }
            : ''),
        };
        this.$axios
          .post(`/Order/${this.$utils.ApiNameByCurrentUser('addToCart')}`, addProduct)
          .then((response) => {
            this.$localStorageMethods.SetCartId(response.data);
            window.dispatchEvent(new Event('CartChangedEventBackend'));
            this.$store.state.loader = false;
            this.popUpComponent = markRaw(ProductAddedToCartPopUpComponent);
            this.showSuccessPopup = true;
          })
          .catch(() => {
            this.$store.state.loader = false;
            this.$utils.ToastNotify('error', 'Something went wrong.');
          });
      }
    },
    GetSubconfigurations(config, index) {
      this.$store.state.loader = true;
      if (this.subconfigurations.length >= index) {
        this.subconfigurations.splice(index, this.subconfigurations.length);
        this.selectedConfigurations.splice(index, this.selectedConfigurations.length);
        this.selectedConfigurations.push(config);
      } else {
        this.selectedConfigurations.push(config);
      }
      if (this.selectedConfigurations.length > 0)
        this.selectedConfigurationValue =
          this.selectedConfigurations[0].Value > 0 ? this.selectedConfigurations[0].Value : 1;
      this.config.Items = this.config.Items.map((item) => ({
        ...item,
        isSelected: item.Id === this.selectedConfigurations[0].Id,
      }));

      if (this.subconfigurations) {
        this.subconfigurations.forEach((subconfig) => {
          subconfig.Configurations = subconfig.Configurations.map((item) => ({
            ...item,
            isSelected: this.selectedConfigurations.filter((x) => x.Id === item.Id).length > 0,
          }));
        });
      }
      this.ConfigurePrice();
      this.$axios
        .get(`/Product/getSubconfigurations/${config.Id}`)
        .then((response) => {
          config.isSelected = true;
          const subconfig = response.data;
          if (subconfig.Configurations.length > 0) {
            subconfig.Configurations = subconfig.Configurations.map((item) => ({
              ...item,
              isSelected: false,
            }));
            this.subconfigurations.push(subconfig);
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    ConfigurePrice() {
      if (this.product.Amount > 0) {
        if (this.selectedConfigurations.length > 0) {
          for (let i = 0; i < this.selectedConfigurations.length; i += 1) {
            if (i == 0) {
              this.price = Number(this.selectedConfigurations[i].Value.toFixed(2));
              this.regularPrice = Number(this.selectedConfigurations[i].Value.toFixed(2));
            } else {
              this.price = Number((this.price + this.selectedConfigurations[i].Value).toFixed(2));
              this.regularPrice = Number(
                (this.regularPrice + this.selectedConfigurations[i].Value).toFixed(2)
              );
            }
          }
          this.price = Number((this.price * this.product.Amount).toFixed(2));
          this.regularPrice = Number((this.regularPrice * this.product.Amount).toFixed(2));
        } else {
          this.price = Number((this.product.Price * this.product.Amount).toFixed(2));
          this.regularPrice = Number((this.product.RegularPrice * this.product.Amount).toFixed(2));
        }
      }
    },
    GetConfigurations(id) {
      this.$store.state.loader = true;
      this.$axios
        .get(`/Product/getConfigurations/${id}`)
        .then((response) => {
          this.config.Items = response.data.Configurations;
          this.config.Category = response.data.Description;
          this.config.Items = this.config.Items.map((item) => ({ ...item, isSelected: false }));
          this.requestsDone += 1;
          if (this.requestsDone > 4) {
            this.$store.state.loader = false;
          }
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetProduct() {
      this.$store.state.loader = true;
      const searchParams = {
        Id: this.productIdParam,
        ProductSlugName: this.productName,
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
      };
      this.$axios
        .get(
          `/Product/${this.$utils.ApiNameByCurrentUser('get')}?${new URLSearchParams(searchParams)}`
        )
        .then((response) => {
          this.product = response.data;
          this.product.Amount = 1;
          if (this.product.PriceDetails.OnSalePrice > 0) {
            this.product.Price = this.product.PriceDetails.OnSalePrice;
            this.product.RegularPrice = this.product.PriceDetails.RegularPrice;
          } else {
            this.product.Price = this.product.PriceDetails.RegularPrice;
            this.product.RegularPrice = this.product.PriceDetails.RegularPrice;
          }
          this.price = this.product.Price;
          this.regularPrice = this.product.RegularPrice;
          this.thumbnailImage = this.product.ImgBase64;
          this.thumbnailImage ? this.gallery.unshift(this.thumbnailImage) : '';
          this.requestsDone += 1;
          if (this.requestsDone > 4) {
            this.$store.state.loader = false;
          }
        })
        .catch((error) => {
          this.$store.state.loader = false;
          if (error && error.response && error.response.status === 404)
            this.$router.push({ name: 'NotFoundPage' });
          else this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetProductByName() {
      this.$store.state.loader = true;
      const searchParams = {
        ProductSlugName: this.productName,
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
      };
      this.$axios
        .get(
          `/Product/${this.$utils.ApiNameByCurrentUser('get')}?${new URLSearchParams(searchParams)}`
        )
        .then((response) => {
          this.product = response.data;
          this.product.Amount = 1;
          if (this.product.PriceDetails.OnSalePrice > 0) {
            this.product.Price = this.product.PriceDetails.OnSalePrice;
            this.product.RegularPrice = this.product.PriceDetails.RegularPrice;
          } else {
            this.product.Price = this.product.PriceDetails.RegularPrice;
            this.product.RegularPrice = this.product.PriceDetails.RegularPrice;
          }
          this.GetProductGallery(this.product.Id);
          this.GetProductReviews(this.product.Id);
          this.GetSuggestedProducts(this.product.Id);
          this.GetConfigurations(this.product.Id);
          this.price = this.product.Price;
          this.regularPrice = this.product.RegularPrice;
          this.thumbnailImage = this.product.ImgBase64;
          this.thumbnailImage ? this.gallery.unshift(this.thumbnailImage) : '';
          this.requestsDone += 1;
          if (this.requestsDone > 4) {
            this.$store.state.loader = false;
          }
        })
        .catch((error) => {
          this.$store.state.loader = false;
          if (error && error.response && error.response.status === 404)
            this.$router.push({ name: 'NotFoundPage' });
          else this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetProductGallery(id) {
      this.$store.state.loader = true;
      this.$axios
        .get(`/Product/getGallery/${id}`)
        .then((response) => {
          const galleryImages = response.data
            .filter((img) => img.ImgBase64)
            .map((img) => img.ImgBase64);
          if (galleryImages) {
            this.gallery = [...this.gallery, ...galleryImages];
            this.ChangeCarouselNumberItems();
          } else this.gallery = [];
          this.requestsDone += 1;
          if (this.requestsDone > 4) {
            this.$store.state.loader = false;
          }
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetProductReviews(id) {
      if (!id) id = this.productIdParam || this.product.Id;
      this.$store.state.loader = true;
      const searchParams = {
        PageSize: this.reviewsFilter.PageSize,
        PageNumber: this.reviewsFilter.PageNumber,
        OrderBy: this.reviewsFilter.OrderBy,
      };
      if (this.reviewsFilter.PageNumber === 1 && this.reviews.length > 0) {
        this.reviews.splice(0, this.reviews.length);
      }
      this.$axios
        .get(
          `/Review/${this.$utils.ApiNameByCurrentUser(
            'getProductReviews'
          )}/${id}?${new URLSearchParams(searchParams)}`
        )
        .then((response) => {
          response.data.Items.forEach((element) => {
            this.reviews.push(element);
          });
          this.reviewsPagination = response.data.PageDetails;
          this.requestsDone += 1;
          if (this.requestsDone > 4) {
            this.$store.state.loader = false;
          }
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    ShowMoreReviews() {
      this.reviewsFilter.PageNumber += 1;
      this.GetProductReviews();
    },
    async ChangeFavoriteStatus(product) {
      if (this.currentUser) {
        this.$store.state.loader = true;
        if (product.IsFavorit === false) {
          await this.$axios.post(`/User/addToFavorite/${product.Id}`);
          product.IsFavorit = true;
        } else {
          await this.$axios.delete(`/User/removeFromFavorite/${product.Id}`);
          product.IsFavorit = false;
        }
        this.$store.state.loader = false;
      } else {
        this.logIn();
      }
    },
    GetSuggestedProducts(id) {
      if (!id) id = this.productIdParam;
      this.$store.state.loader = true;
      const searchParams = {
        Size: 4,
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
      };
      this.$axios
        .get(
          // eslint-disable-next-line comma-dangle
          `/Product/${this.$utils.ApiNameByCurrentUser('getSuggested')}/${id}?${new URLSearchParams(
            searchParams
          )}`
        )
        .then((response) => {
          this.suggestedProducts = response.data;
          this.requestsDone += 1;
          if (this.requestsDone > 4) {
            this.$store.state.loader = false;
          }
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetUserDetails() {
      if (this.currentUser) {
        this.$store.state.loader = true;
        this.$axios
          .get('/User/getDetails')
          .then((response) => {
            this.user = response.data;
            this.$refs.addReviewModalRef.ClearModal();
            this.requestsDone += 1;
            if (this.requestsDone > 4) {
              this.$store.state.loader = false;
            }
            // eslint-disable-next-line no-undef
            $('#addReviewModal').modal('show');
          })
          .catch(() => {
            this.$store.state.loader = false;
            this.$utils.ToastNotify('error', 'Something went wrong.');
          });
      } else {
        this.$utils.ToastNotify(
          'warning',
          'Pentru a adăuga o recenzie, trebuie să fii autentificat.'
        );
      }
    },
    ChangeCarouselNumberItems(e) {
      const windowWidth = e ? e.target.innerWidth : window.innerWidth;
      this.windowWidth = windowWidth;
      switch (true) {
        case windowWidth > 1200:
          this.galleryItems = 5;
          break;
        case windowWidth > 992 && windowWidth <= 1200:
          this.galleryItems = 3;
          break;
        case windowWidth > 768 && windowWidth <= 992:
          this.galleryItems = 2;
          break;
        case windowWidth > 576 && windowWidth <= 768:
          this.galleryItems = 3;
          break;
        default:
          this.galleryItems = 2;
          break;
      }
      this.galleryItems >= this.gallery.length ? (this.galleryItems = this.gallery.length) : '';
    },
    CloseSuccessPopUp() {
      this.showSuccessPopup = false;
      this.SuccessPopup = null;
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    Schema() {
      return yup.object({
        configurations: yup
          .array()
          .of(
            yup.object().shape({
              isSelected: yup.boolean().default(false),
            })
          )
          .test('at-least-one-selected', 'Selectează o opțiune.', (value) => {
            if (value) {
              const atLeastOneSelected = value.some((item) => item.isSelected === true);
              return atLeastOneSelected;
            }
            return true;
          }),
      });
    },

    recursiveSchema() {
      return yup.object({
        config: yup.array().of(
          yup.object().shape({
            specifications: yup
              .array()
              .of(
                yup.object().shape({
                  isSelected: yup.boolean().default(false),
                })
              ) // Pass the reference to the schema function
              .test('at-least-one-selected', 'Selectează o opțiune.', (value) => {
                if (value) {
                  const atLeastOneSelected = value.some((item) => item.isSelected === true);
                  return atLeastOneSelected;
                }
                return true;
              }),
            // eslint-disable-next-line comma-dangle
          })
        ),
      });
    },
  },
  watch: {
    showSuccessPopup: {
      handler(value) {
        if (value === true) {
          setTimeout(() => this.CloseSuccessPopUp(), 8000);
        }
      },
      deep: true,
    },
  },
  created() {
    this.currentURL = window.location.href;
    if (this.productIdParam) {
      this.GetProduct();
      this.GetProductGallery(this.productIdParam);
      this.GetProductReviews(this.productIdParam);
      this.GetSuggestedProducts(this.productIdParam);
      this.GetConfigurations(this.productIdParam);
    }
    if (this.productName && !this.productIdParam) {
      this.GetProductByName();
    }
  },
  mounted() {
    window.addEventListener('resize', this.ChangeCarouselNumberItems);
  },
  unmounted() {
    window.removeEventListener('resize', this.ChangeCarouselNumberItems);
  },
};
</script>

<style scoped>
.not-available-product {
  background-color: var(--neutral-1);
  color: var(--primary-5);
  border: 1px solid var(--primary-5);
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 8px;
  line-height: 12px;
  display: inline-block;
}
.amount-buttons .button {
  background-color: #f0f2f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

@media (max-width: 576px) {
  .add-first-review-img {
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .add-first-review-img {
    max-width: 50vw;
  }
}

.out-of-stock {
  color: var(--secondary-accents-danger-05, #dd2531) !important;
}
.no-images-text {
  color: var(--neutral-5);
  font-size: 18px;
  font-style: italic;
  text-align: center;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  line-height: 22.5px;
}

.original-price {
  color: var(--danger-5) !important;
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 25px */
}
.money-saved-badge-position {
  transform: rotate(45deg);
  flex-shrink: 0;
  position: absolute;
  top: 9px;
  right: -42px;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.5));
}
.money-saved-badge .heading-06 {
  font-size: 20px !important;
}
.money-saved-badge {
  background: linear-gradient(139deg, #dd2531 12.55%, #b11e27 88.32%);
  width: 142px;
  height: 41.477px;
  clip-path: polygon(30% 0%, 70% 0, 100% 100%, 0% 100%);
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}
.money-saved-badge-position::after {
  content: ' ';
  background-image: url('../../assets/images/icons/badge-product-page-left-corner.svg');
  position: absolute;
  width: 12px;
  height: 6px;
  transform: rotate(45deg);
  top: 40px;
  right: -3px;
}
.money-saved-badge-position::before {
  content: ' ';
  background-image: url('../../assets/images/icons/badge-product-page-left-corner.svg');
  position: absolute;
  width: 12px;
  height: 6px;
  transform: rotate(-44deg);
  top: 40px;
  left: -3px;
}
@media (min-width: 1400px) {
  .money-saved-badge .heading-06 {
    font-size: 24px !important;
  }
  .money-saved-badge {
    width: 205px;
    height: 61.477px;
    clip-path: polygon(30% 0%, 70% 0, 100% 100%, 0% 100%);
  }
  .money-saved-badge-position {
    transform: rotate(45deg);
    flex-shrink: 0;
    position: absolute;
    top: 14px;
    right: -58px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.5));
  }
  .money-saved-badge-position::after {
    width: 12px;
    height: 6px;
    transform: rotate(45deg);
    top: 60px;
    right: -3px;
  }
  .money-saved-badge-position::before {
    width: 12px;
    height: 6px;
    transform: rotate(-44deg);
    top: 60px;
    left: -3px;
  }
}

.config-box .description {
  position: absolute;
  top: -11px;
  background: white;
  padding: 2px 4px 2px 8px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24em;
  color: var(--primary-5);
  text-transform: uppercase;
}
.config-box {
  border: 1px solid var(--neutral-3);
  padding: 24px 24px 18px 24px;
  border-radius: 12px;
  position: relative;
}

.card.review .card-body .date {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.15em;
  text-align: left;
  color: var(--neutral-5);
}
.card.review .card-body {
  padding: 16px;
}
.card.review {
  border: 1px solid var(--neutral-2);
  border-radius: 8px;
}

.reviews-number {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: var(--neutral-5);
}
.gallery-carousel {
  margin-left: -7px;
  margin-right: -16px;
}

.content {
  max-width: 806px;
  margin: auto;
  display: block;
}
.section {
  margin-top: 35px;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
    90deg,
    rgba(223, 210, 187, 0) 0%,
    rgba(223, 210, 187, 0.6) 9.37%,
    #bfa577 48.44%,
    rgba(223, 210, 187, 0.6) 90.1%,
    rgba(223, 210, 187, 0) 100%
  );
  border-image-slice: 1;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.total-price-title {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--neutral-3);
  margin-bottom: 13px;
  display: inline-block;
}
.favorite-circle img {
  width: 24px;
}
.favorite-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 15px 0px #00000026;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.gallery-images.active {
  box-shadow: 4px 4px 12px 0px #00000040;
  border: 2px solid var(--primary-5);
  padding: 3px;
  background-color: white;
}
.gallery-images {
  height: 90px;
  width: 100px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}
.gallery-thumbnail {
  height: 30vh;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0px 5px 60px 0px #0000001a;
  border: 1px solid #bfa577;
  /* border-image: linear-gradient(
    99.35deg,
    rgba(191, 165, 119, 0.7) 0%,
    rgba(223, 210, 187, 0.7) 8.31%,
    rgba(149, 105, 29, 0.7) 14.58%,
    rgba(223, 210, 187, 0.7) 40.1%,
    rgba(191, 165, 119, 0.7) 66.84%,
    rgba(244, 240, 232, 0.7) 89.12%,
    rgba(149, 105, 29, 0.7) 100%
  ); */
}

@media (max-width: 650px) {
  .set-overflow {
    overflow: hidden;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1250px;
  }
  .gallery-thumbnail {
    height: 500px;
  }
}

/*----------------------------------*/
.input-counter {
  width: 71px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #d6366c;
  background: var(--main-colors-neutral-1-white, #fff);
}

.borders {
  border-radius: 4px;
  border: 1px solid var(--CardBorder, #bfa577);
}

.pointer {
  cursor: pointer;
}

.images {
  max-height: 200px;
  width: 100%;
  margin: 10px;
  cursor: pointer;
}

.products-title {
  color: var(--secondary-accents-secondary-03, #bfa577);
  padding-bottom: 20px;
  border-bottom: 2px solid var(--secondary-accents-secondary-03, #bfa577);
}

.box {
  border-radius: 8px;
  border: 1px solid var(--primary-neutral-neutral-02, #f0f2f3);
  padding: 16px;
}

.pointer {
  cursor: pointer;
}

.heart {
  border-radius: 222px;
  background: var(--primary-neutral-neutral-01-white, #fff);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  color: #ff0505;
  padding: 9px 8px 6px 8px;
}

.info {
  border-radius: 50px;
  padding: 7px;
  border: 1px solid gray;
}

.review-aproved {
  color: var(--secondary-accents-success-06, #509920);
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
}

.review-pending {
  color: var(--secondary-accents-info-06, #048e9d);
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
}

.configuration-price {
  font-weight: 500;
  font-size: 12px;
}

.configuration-color {
  color: var(--primary-5);
}
</style>
